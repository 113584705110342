<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png"
             alt
             @click="goBack" />
        <span class="center">项目管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">项目列表</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">项目详情</span>
      </div>
      <div>
        <div class="right_icon"></div>
      </div>
    </div>
    <el-card>
      <Divider :title="'基础信息'"
               style="margin: 10px 0">
        <span slot="right">
          <span style="color: blue;font-size:14px"
                v-access
                data-ctrl="projectChangeSubject"
                @click="goPeople()">更换负责人</span>
        </span>
      </Divider>
      <replacePeople @close="close()"
                     :code="code"
                     :peopleShow="peopleShow"></replacePeople>
      <el-descriptions class="margin-top"
                       style="margin-top: 20px"
                       :column="3">
        <el-descriptions-item label="项目编号">
          <span>{{ detailInfo.code }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="项目名称">
          {{
          detailInfo.name || "--"
          }}
        </el-descriptions-item>
        <el-descriptions-item label="项目联系人">
          <span v-if="detailInfo.subject">{{ detailInfo.subject.name }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="合作企业">
          <span>{{ detailInfo.companyName }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="合同金额">
          <span>￥{{ detailInfo.contractMoney | money }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="状态">
          <el-tag v-if="detailInfo.status === '1'">正常</el-tag>
          <el-tag type="success"
                  v-else>结束</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="项目时间"
                              span="2">
          <span>
            创建于{{ detailInfo.create_time | dateVal }},结束于{{
            detailInfo.endTime || "项目暂未结束"
            }}
          </span>
        </el-descriptions-item>

        <el-descriptions-item label="项目备注">
          {{
          detailInfo.remark
          }}
        </el-descriptions-item>
      </el-descriptions>
      <Divider :title="'账户信息'"
               v-if="accounts.length"
               style="margin: 10px 0">
        <!-- <span slot="right">
          <span style="color: blue;font-size:14px" @click="addbank()">新增账户</span>
        </span>-->
      </Divider>
      <el-dialog :visible.sync="dialogVisible"
                 width="30%"
                 :show-close="false">
        <div slot="title"
             style="text-align:center;font-weight:700;font-size:16px">新增账户</div>
        <div style="display: flex">
          <span style="margin-right:10px">支付银行:</span>
          <el-checkbox-group v-model="payChannel">
            <el-checkbox v-for="(item,index) in payChannelList"
                         :label="item.name"
                         :key="index">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div style="display: flex">已有账户：{{payChannel.toString()}}</div>

        <span slot="footer"
              class="dialog-footer">
          <el-button type="primary"
                     @click="addBankSubmit()">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
      <div class="bank_Calss"
           v-for="item in accounts"
           :key="item.id">
        <div class="class_top">
          <div>
            <span v-if="item.branchName.includes('网商')">网商银行</span>
            <span v-else>平安银行</span>
          </div>
          <!-- <div>
            <el-button
              type="text"
              v-access
              data-ctrl="projectAccountInfo"
              @click="getBalance(item.code,item.projectCode)"
            >查看余额</el-button>
          </div> -->
        </div>
        <el-descriptions style="padding:20px">
          <el-descriptions-item label="分行/支行">
            {{
            item.branchName
            }}
          </el-descriptions-item>
          <el-descriptions-item label="账户卡号">
            {{
            item.accountCode
            }}
          </el-descriptions-item>
          <el-descriptions-item label="联行号">
            {{
            item.branchNo
            }}
          </el-descriptions-item>
          <el-descriptions-item label="账户余额">
            <span v-if="item.isShow">{{ item.balance | money }}元</span>
            <span v-else>****</span>
          </el-descriptions-item>
          <el-descriptions-item label="已借款">
            <span v-if="item.isShow">{{ item.borrow }}元</span>
            <span v-else>****</span>
          </el-descriptions-item>

          <el-descriptions-item label="账户名称">{{item.accountName}}</el-descriptions-item>
        </el-descriptions>
      </div>

      <!-- <el-descriptions class="margin-top" style="margin-top: 20px" :column="3">
        <el-descriptions-item label="户名">
          <span v-if="detailInfo.enterprise">
            {{
            detailInfo.enterprise.name || '--'
            }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item label="账号"></el-descriptions-item>
        <el-descriptions-item label="支行"></el-descriptions-item>

        <el-descriptions-item label="联行号"></el-descriptions-item>
        <el-descriptions-item label="余额">
          <div>
            <span v-if="balance">{{ balance | money }}元</span>
            <span v-else>****</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="已借款">
          <div></div>
        </el-descriptions-item>
      </el-descriptions>-->

      <Divider :title="'组员信息'"
               style="margin: 10px 0">
        <span slot="right">
          <span v-access
                type="text"
                style="color: blue; margin-left: 10px;font-size:14px"
                data-ctrl="projectAddUser"
                @click="getAddPeople">添加成员</span>
          <span v-access
                type="text"
                style="color: blue; margin-left: 10px;font-size:14px"
                data-ctrl="projectAddUser"
                @click="exportAll">导出项目成员</span>
        </span>
      </Divider>

      <el-table :data="tableData"
                style="width: 100%; margin-top: 20px">
        <el-table-column label="姓名">
          <template slot-scope="scope">
            <span v-if="scope.row.user">{{ scope.row.user.name || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="电话">
          <template slot-scope="scope"
                    v-if="scope.row.user">{{ scope.row.user.phone }}</template>
        </el-table-column>
        <el-table-column label="加入时间"
                         align="center">
          <template slot-scope="scope">
            {{
            scope.row.startTime | dateVal
            }}
          </template>
        </el-table-column>
        <el-table-column label="退出时间"
                         align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.endTime">
              {{
              scope.row.endTime | dateVal
              }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="操作"
                         align="center">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.endTime"
                       v-access
                       data-ctrl="projectRemoveUser"
                       @click="goDel(scope.row.userCode)"
                       type="text">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form>
        <Divider :title="'工资包审批设置'"></Divider>
        <div class="tags">审核批准人员:</div>
        <el-row style="width: 100%"
                v-for="(item1, a) in checkList"
                :key="'index1-' + a">
          <el-col :span="12">
            <el-form-item :label="item1.name + (a + 1)"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="false"
                          v-model="item1.value"
                          :key="'k' + a"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tags">复核操作人员:</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="true"
                          :key="'gzbysr'"
                          v-model="checkerCode.value"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'借款审批设置'"></Divider>
        <div class="tags">审核批准人员:</div>
        <el-row style="width: 100%"
                v-for="(item2, b) in loanList"
                :key="'index2-' + b">
          <el-col :span="12">
            <el-form-item :label="item2.name + (b + 1)"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="false"
                          v-model="item2.value"
                          :key="'j' + b"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tags">复核操作人员:</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="true"
                          :key="'jkysr'"
                          v-model="carryforwardCode.value"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'还款审批设置'"></Divider>
        <div class="tags">审核批准人员:</div>
        <el-row style="width: 100%"
                v-for="(item3, c) in repaymentList"
                :key="'index3-' + c">
          <el-col :span="12">
            <el-form-item :label="item3.name + (c + 1)"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="false"
                          v-model="item3.value"
                          :key="'l' + c"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tags">复核操作人员:</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="true"
                          :key="'hkysr'"
                          v-model="repaymentCode.value"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <Divider :title="'结转审批设置'"></Divider>
        <div class="tags">审核批准人员:</div>
        <el-row style="width: 100%"
                v-for="(item4, d) in carryforwardList"
                :key="'index4-' + d">
          <el-col :span="12">
            <el-form-item :label="item4.name + (d + 1)"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="false"
                          v-model="item4.value"
                          :key="'p' + d"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tags">复核操作人员:</div>
        <el-row style="width: 100%">
          <el-col :span="12">
            <el-form-item label="复核人"
                          required
                          style="width: 100%">
              <SelectUser :type="type"
                          :isChecker="true"
                          :key="'jzysr'"
                          v-model="carryforwardCode.value"></SelectUser>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog :visible.sync="addShow"
                 :show-close="false"
                 title="添加项目成员">
        <el-input v-model="peopleKeywords"
                  style="    width: 200px;
    float: right;
    margin: 10px;"
                  @input="userLogoData"
                  placeholder="请输入姓名">
          <i slot="prefix"
             class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-table style="margin-top: 10px"
                  :data="gridData"
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection"
                           width="55"></el-table-column>
          <el-table-column property="name"
                           label="姓名"></el-table-column>
          <el-table-column property="phone"
                           label="手机号"></el-table-column>
          <el-table-column property="create_time"
                           label="注册时间"></el-table-column>
        </el-table>
        <!-- <div class="pagination">
          <span class="clickColor">共{{ total }}条</span>
          <span class="clickColor" style="margin: 0 10px">每页{{ pageSize }}条</span>
          <el-pagination
            background
            :current-page.sync="pageNumber"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="prev,pager, next,jumper"
            :total="total"
          ></el-pagination>
        </div>-->
        <div slot="footer"
             class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary"
                     @click="onSubmit">添加</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import moment from 'moment';
import { api } from '../../api/base/index';
import { parseJsonToTable } from '/src/lib/dev';
export const projectInfo = api()('project.info.json');

import { userList } from '../../api';
export const projectAddUser = api()('project.addUser.json');
export const projectUserList = api()('project.userList.json');
export const projectRemoveUser = api()('project.removeUser.json ');
export const projectAccountInfo = api()('project.account.info.json ');

export default {
  components: {
    replacePeople: () => import('./replacePeople.vue'),
    // AddPeople:()=>import('./addPeople.vue'),
    Divider: () => import('../../components/divider.vue'),
    SelectUser: () => import('../task/selectUser.vue'),
  },
  data () {
    return {
      type: '工资包',
      dialogVisible: false,
      payChannelList: [
        { name: '网商银行', code: 'm1', },
        { name: '平安银行', code: 'p1', },
      ],
      payChannel: ['网商银行', '平安银行'],
      checkList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],

      loanList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      repaymentList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      carryforwardList: [
        {
          name: '审批人',
          value: {
            subjectAccount: '',
          },
        },
      ],
      checkerCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },

      loanCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      repaymentCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      carryforwardCode: {
        name: '验收人员',
        value: {
          subjectAccount: '',
        },
      },
      detailInfo: {},
      code: '',
      tableData: [],
      accounts: [],
      gridData: [],
      value1: '',
      startTime: '',
      endTime: '',
      keywords: '',
      peopleKeywords: '',
      peopleShow: false,
      addShow: false,
      pageSize: 5,
      pageNumber: 1,
      total: 0,
      selectList: [],
      balance: '',
      borrow: ''
    };
  },
  created () {
    this.code = this.$route.query.code;

    this.logoData();
    this.getuserList();
  },
  methods: {
    goBack () {
      window.history.go(-1);
    },
    addbank () {
      this.dialogVisible = true
    },
    addBankSubmit () {

      this.$message.success('操作成功')
    },
    logoData () {
      const params = {
        code: this.code,
      };
      projectInfo(params).then((res) => {
        this.detailInfo = res
        this.accounts = this.detailInfo.accounts.map(it => ({ ...it, isShow: false, balance: '', borrow: '' }))
        console.log(this.accounts, 6666)
        const rs = res;

        let _checkList = [];
        rs.taskViewers.split(',').forEach((it) => {
          _checkList.push({
            name: '审批人',
            value: {
              subjectAccount: it,
            },
          });
        });
        this.checkList = _checkList;
        this.checkerCode.value.subjectAccount = rs.taskReviewer;

        let _loanList = [];
        rs.borrowViewers.split(',').forEach((it) => {
          _loanList.push({
            name: '审批人',
            value: {
              subjectAccount: it,
            },
          });
        });

        this.loanList = _loanList;
        this.loanCode.value.subjectAccount = rs.borrowReviewer;

        let _carryforwardList = [];

        rs.carryOverViewers.split(',').forEach((it) => {
          _carryforwardList.push({
            name: '审批人',
            value: {
              subjectAccount: it,
            },
          });
        });
        this.carryforwardList = _carryforwardList;

        this.carryforwardCode.value.subjectAccount = rs.carryOverReviewer;

        let _repaymentList = [];
        rs.payBackViewers.split(',').forEach((it) => {
          _repaymentList.push({
            name: '审批人',
            value: {
              subjectAccount: it,
            },
          });
        });
        this.repaymentList = _repaymentList;
        this.repaymentCode.value.subjectAccount = rs.payBackReviewer;

      });
    },
    getBalance (code, projectCode) {
      const params = {
        projectCode: projectCode,
        subCode: code
      };
      projectAccountInfo(params).then((res) => {
        this.accounts.map(it => {
          if (it.code === code) {
            it.isShow = !it.isShow
            it.balance = res.balance
            it.borrow = res.borrow.toString()
          }
        })

        // this.balance = res.balance;
        // this.borrow = res.borrow.toString();

      });
    },

    handleSelectionChange (val) {
      this.selectList = val.map((it) => it.code);

    },
    async getuserList () {
      const params = {
        keywords: this.keywords,
        projectCode: this.code,
        start_time: this.startTime,
        end_time: this.endTime,
        pageNumber: 1,
        pageSize: 10000,
      };
      const res = await projectUserList(params);
      this.tableData = res.list;
    },
    exportAll () {
      // const res = this.tableData.map(it => it.user)
      const time = moment().format('YYMMDDHHmm');
      const data = this.tableData.map((it) => {
        const _errors = [];
        if (it.user.hasBankCardError) {
          _errors.push('银行卡信息错误');
        } else if (it.user.certificationError) {
          _errors.push('未认证');
        } else if (it.user.dayLimitError) {
          _errors.push('日交易额超出限制');
        } else if (it.user.existError) {
          _errors.push('用户不存在');
        } else if (it.user.monthLimitError) {
          _errors.push('月交易额超出限制');
        } else if (it.user.singleLimitError) {
          _errors.push('超出单笔限额');
        } else if (it.user.taxLimitError) {
          _errors.push('超出个税起征点');
        } else if (it.user.repeatError) {
          _errors.push('人员重复');
        } else if (it.user.hasSubAccountError) {
          _errors.push('未开通子帐户');
        } else {
          _errors.push('认证成功');
        }
        return {
          ...it,
          endTime: it.endTime ? moment(it.endTime).format('YYYY-MM-DD') : '',
          startTime: moment(it.startTime).format('YYYY-MM-DD'),
          certificationError: _errors.join(','),
        };
      });

      parseJsonToTable(
        `项目成员_${time} `,
        {
          姓名: 'user.name',
          手机号: 'user.phone',
          身份证号: 'user.idCardNumber',
          加入时间: 'startTime',
          退出时间: 'endTime',
          对比结果: 'certificationError',
        },
        data,
        true
      );
    },
    async userLogoData () {
      const params = {
        keywords: this.peopleKeywords,
        pageNumber: 1,
        pageSize: 10000,
      };
      const res = await userList(params);
      this.gridData = res.list.filter(it => it.isAuthenticated);
      this.total = this.gridData.length;

    },
    async goDel (code) {
      await projectRemoveUser({
        code: this.code,
        userCode: code,
      });

      this.getuserList();
    },
    getAddPeople () {
      this.addShow = true;
      this.userLogoData();
    },
    closeDialog () {
      this.addShow = false;
    },

    onSubmit () {
      projectAddUser({
        userCodes: this.selectList,
        code: this.code,
      }).then(() => {
        this.closeDialog();
        this.getuserList();
      });
    },
    async dateChange (value) {
      this.startTime = moment(value[0]).format('YYYY-MM-DD');
      this.endTime = moment(value[1]).add(1, 'days').format('YYYY-MM-DD');

      const params = {
        keywords: this.keywords,
        pageNumber: 1,
        start_time: this.startTime,
        end_time: this.endTime,
        projectCode: this.code,
        pageSize: 10000,
      };
      const res = await projectUserList(params);
      this.tableData = res.list;
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/customer/moneyDetail',
        query: { code },
      });
    },
    close () {
      this.peopleShow = false;
      this.addShow = false;
      this.logoData();
    },
    goPeople () {
      this.peopleShow = true;
    },
  },
};
</script>
<style lang="scss" scope>
.right_icon {
  display: flex;
}
.el-descriptions-item__container {
  align-items: center;
}
.tags {
  font-size: 14px;
  text-align: left;
  margin: 0;
  color: #409eff;
}
.bank_Calss {
  width: 100%;

  border-radius: 5px;
  border: #8798af solid 1px;
  margin: 10px 0;
  .class_top {
    background: #f2f2f2;
    padding: 5px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
// .el-button {
//   padding: 0;
// }
</style>